<template>
    <div class="text-block">
        <h3 class="title" v-if="title1">
            <img v-if="icon" class="icon" v-bind:src="iconSrc" /> 
            <span class="move-right">{{ $t(title1) }}</span> {{ $t(title2) }} 
        </h3>
        <p class="description">{{ $t(description) }}</p>
    </div>
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    title1: String,
    title2: String,
    description: String,
    icon: String,
  },
  computed: {
    iconSrc: function () {
      return require ('../../assets/img/' + this.icon);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../assets/css/scss/text-block.scss';

.cn .szg-header {
    .move-right {
        position: relative;
        right: -15px;
    }
}
</style>
