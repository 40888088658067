<template>
  <div class="szg page">
    <Header />
    <Cards ref="szgCards"/>
    <SectionFeatures/>
    <Demo ref="szgDemo"/> 
    <Download />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/szg/SectionHeader.vue'
import Cards from '@/components/szg/SectionCards.vue'
import SectionFeatures from '@/components/home/SectionFeatures.vue'
import Demo from '@/components/szg/Demo.vue'
import Download from '@/components/szg/Downloads.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "szg",
  head: {
    title: { inner: 'Digital Valley' }
  },
  components: {
    Header,
    Cards,
    SectionFeatures,
    Demo,
    Download, 
    Footer

  }, 
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }, 
  methods: {
    handleScroll(){
      this.$refs.szgDemo.handleScroll()
      this.$refs.szgCards.handleScroll()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

