<template>
  <div class="szg-download">
    <div class="download-box">
      <TextBlock  class="text-left" :title="$t('download.headerTitle1') + $t('download.headerTitle2')" :description="$t('download.description')"/>
      <DownloadBtn platform="ios" class="dlbtn"/>
      <DownloadBtn platform="andriod" class="dlbtn"/>
    </div>

    <div class="white-overlay">
      <div class="oval"></div>
    </div>
  </div>
</template>

<script>
import TextBlock from "@/components/partials/TextBlock.vue";
import DownloadBtn from "@/components/partials/DownloadBtn.vue";

export default {
  components: {
    TextBlock,
    DownloadBtn
  }
};
</script>

<style lang="scss">
.szg-download {
  height: 43vw; // to be removed later
  overflow: hidden;

  @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
    height: auto;
    padding-bottom: 200px;
  }

  @media (max-width: 767.98px) {
    margin-top: 11.5706806vw;
    padding-top: calc(60px + 11.5706806vw);
    padding-bottom: 260px;
    height: initial;
    background-color: #E9EEF1; 
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    padding-bottom: 120px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 227px;
    background: url('../../assets/img/bg-szg-download.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: 1199.98px) { // 991.98px
      top: 0;
      height: 11.5706806vw;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 40%;
    bottom: 0;
    height: 610px;
    width: 880px;
    background: url('../../assets/img/dl-mockup.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      left: 27%;
      bottom: 0;
      height: 460px;
      width: 660px;
    }

    @media (max-width: 767.98px) {
      left: unset;
      right: 0;
      bottom: 0;
      height: 240px;
      width: 0350px;
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
          right: 0;
          bottom: 0;
          height: 240px;
          left: 0;
          width: auto;
    }
  }

  // Background Image
  background-color: #e9eef1;

  // Spacing
  margin-left: -15px;
  margin-right: -15px;

  position: relative;

  .download-box{
    position: absolute;
    top: 40%;
    left: 14%;
    width: 415px;

    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      position: relative;
      left: unset;
      top: unset;
      margin-top: 180px;
      margin-left: 60px;
      width: calc(50% - 30px);
    }

    @media (max-width: 767.98px) {
      position: relative;
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
    }

    .text-block {
      font-size: 16px;
      .title{
        color: #2f487f;
        font-size: 2em;
        text-transform: uppercase;

        @media (max-width: 767.98px) {
          font-size: 24px;
        }
      }
      .description{
        color: #58595b;
      }
    }

    .dlbtn {
      display: block;
      margin: 20px 0;
      background-color: #3B4984;

      @media (max-width: 767.98px) {
        font-size: 14px;
      }

      &:hover {
        background-color: #28316f;
      }
    }

    .icon {
      @media (max-width: 767.98px) {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.cn .szg-download {
  .download-box .text-block {
    .title {
      font-weight: 700;
    }

    .description {
      font-weight: 100;
    }
  }
}
</style>
