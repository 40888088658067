
<template>
  <div class="szg-demo">
    <!-- section 1 -->
    <div class="row demo-row" id="demo1">
      <div class="col-12 col-md">
        <div class="demo-screen-1">
          <transition enter-active-class="animated fadeInDown slow">
            <div class="bg-box1" v-if="showDemo1"></div>
          </transition>
          <transition enter-active-class="animated fadeInLeft slow">
            <div class="bg-box2" v-if="showDemo1"></div>
          </transition>
          <transition enter-active-class="animated fadeIn delay-1s slower">
            <img class="screen" src="@/assets/img/screen-1.png" v-if="showDemo1">
          </transition>
        </div>
      </div>
      <div class="col-12 col-md">
        <TextBlock
          number="01."
          :title="$t('szgDemo.title1')"
          :subtitle="$t('szgDemo.subtitle1')"
          :description="$t('szgDemo.description1')"
        />
      </div>
    </div>

    <!-- section 2 -->
    <div class="row demo-row" id="demo2">
      <div class="col-12 col-md order-md-12">
        <div class="demo-screen-2">
          <transition enter-active-class="animated fadeInDown slow">
            <div class="bg-box1" v-if="showDemo2"></div>
          </transition>
          <transition enter-active-class="animated fadeInLeft slow">
            <div class="bg-box2" v-if="showDemo2"></div>
          </transition>
          <transition enter-active-class="animated fadeIn delay-1s slower">
            <img class="screen" src="@/assets/img/screen-2.png"  v-if="showDemo2">
          </transition>
        </div>
      </div>
      <div class="col-12 col-md order-md-1">
        <TextBlock
          number="02."
          :title="$t('szgDemo.title2')"
          :subtitle="$t('szgDemo.subtitle2')"
          :description="$t('szgDemo.description2')"
        />
      </div>
    </div>

    <!-- section 3 -->
    <div class="row demo-row" id="demo3">
      <div class="col-12 col-md">
        <div class="demo-screen-3">
          <transition enter-active-class="animated fadeInDown slow">
            <div class="bg-box1" v-if="showDemo3"></div>
          </transition>
          <transition enter-active-class="animated fadeInLeft slow">
            <div class="bg-box2" v-if="showDemo3"></div>
          </transition>
          <transition enter-active-class="animated fadeIn delay-1s slower">
            <img class="screen" src="@/assets/img/screen-3.png"  v-if="showDemo3"> 
          </transition>
        </div>
      </div>
      <div class="col-12 col-md">
        <TextBlock
          number="03."
          :title="$t('szgDemo.title3')"
          :subtitle="$t('szgDemo.subtitle3')"
          :description="$t('szgDemo.description3')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextBlock from "@/components/partials/TextBlock2.vue";

export default {
  data: function() {
    return {
      showDemo1: false,
      showDemo2: false,
      showDemo3: false
    };
  },
  components: {
    TextBlock
  },
  methods: {
    isInViewport(el) {
      let elementTop = el.offsetTop;
      let elementBottom = elementTop + el.offsetHeight;

      let viewportTop = window.scrollY;
      let viewportBottom = viewportTop + window.innerHeight;
      return elementBottom > viewportTop && elementTop < viewportBottom;
    },
    toggleScroll(demoNo) {
      let el = this.$el.querySelector(`#demo${demoNo}`);

      if (this.isInViewport(el)) {
        this[`showDemo${demoNo}`] = true;
      } else {
        this[`showDemo${demoNo}`] = false;
      }
    },
    handleScroll() {
      this.toggleScroll(1);
      this.toggleScroll(2);
      this.toggleScroll(3);
    }
  }
};
</script>

<style lang="scss" scoped>
.szg-demo {
  .demo-row {
    margin: 20vh;

    @media (max-width: 767.98px) {
      margin: 0 -15px;
    }

    &:not(:last-child) {
      margin-bottom: 40vh;

      @media (max-width: 767.98px) {
        margin-bottom: 90px;
      }
    }

    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      &:first-child {
        margin-top: 120px;
        margin-bottom: 320px;
      }

      &:nth-child(2) {
        margin-bottom: 380px;
      }

      &:nth-child(3) {
        margin-bottom: 200px;
      }

      margin: 0;
    }
  }
  .text-block-2 {
    margin-top: 50px;
  }
  .col {
    display: flex;
    justify-content: center;
  }
}

.demo-screen-1,
.demo-screen-2,
.demo-screen-3 {
  margin: 0 auto;
}

.demo-screen-1 {
  height: 400px;
  width: 400px;
  position: relative;

  @media (max-width: 767.98px) {
    height: 137vw;
    width: 100%;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 70vw;
  }

  .screen,
  .bg-box1,
  .bg-box2 {
    position: absolute;
  }
  .screen {
    width: 57.5%;
    top: 6.25%;
    left: 19.5%;

    @media (max-width: 767.98px) {
      width: 70.9%;
      top: 60px;
      left: 14.55%;
    }
  }
  .bg-box1 {
    height: 98%;
    width: 98%;
    top: 0;
    left: 0;

    background: rgba(61, 96, 154, 0.4);

    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      left: -30px;
    }

    @media (max-width: 767.98px) {
      left: -15px;
      height: 350px;
      width: 60%;
    }
  }
  .bg-box2 {
    height: 74%;
    width: 105%;
    top: 290px;
    left: 100px;
    background: rgba(112, 81, 160, 0.3);

    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      width: 80%;
    }

    @media (max-width: 767.98px) {
      left: unset;
      top: 200px;
      right: -15px;
      height: 300px;
      width: 90%;
    }
  }
}
.demo-screen-2 {
  height: 500px;
  width: 326px;
  position: relative;

  @media (max-width: 767.98px) {
    height: 156vw;
    width: 100%;
    margin-bottom: 120px;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 86vw;
  }

  .screen,
  .bg-box1,
  .bg-box2 {
    position: absolute;
  }
  .screen {
    width: 70.9%;
    top: 6.25%;
    left: 16.5%;

    @media (max-width: 767.98px) {
      left: 14.55%;
    }
  }
  .bg-box1 {
    height: 62%;
    width: 100%;
    top: 0;
    left: 0;

    background: rgba(61, 96, 154, 0.4);

    @media (max-width: 767.98px) {
      left: -15px;
      height: 290px;
      width: 100%;
    }
  }
  .bg-box2 {
    height: 116%;
    width: 65.95%;
    top: 120px;
    left: 160px;

    background: rgba(112, 81, 160, 0.3);

    @media (max-width: 767.98px) {
      left: unset;
      top: 200px;
      right: -15px;
      height: 410px;
      width: 80%;
    }
  }
}
.demo-screen-3 {
  height: 425px;
  width: 300px;
  position: relative;

  @media (max-width: 767.98px) {
    height: 132vw;
    width: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 75vw;
  }

  .screen,
  .bg-box1,
  .bg-box2 {
    position: absolute;
  }
  .screen {
    width: 70.9%;
    top: 6.25%;
    left: 26.5%;

    @media (max-width: 767.98px) {
      width: 70.9%;
      left: 14.55%;
    }
  }
  .bg-box1 {
    height: 80.7%;
    width: 68.33%;
    top: 0;
    left: 0;
    background: rgba(61, 96, 154, 0.4);

    @media (max-width: 767.98px) {
      left: -15px;
      height: 290px;
      width: 50%;
    }
  }
  .bg-box2 {
    height: 74.7%;
    width: 130%;
    top: 170px;
    left: 0;
    background: rgba(112, 81, 160, 0.3);

    @media (max-width: 767.98px) {
      left: unset;
      right: -15px;
      height: 290px;
      width: 100%;
    }
  }
}

#demo1,
#demo2,
#demo3 {
  > .col-12 {
    @media (min-width: 768px) and (max-width: 1199.98px) { // 991.98px
      max-width: 50%;
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      max-width: 50%;
    }
  }
}
</style>
