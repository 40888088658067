<template>
  <div class="szg-cards">
    <Card
      v-for="(card, index) in cards"
      :key="card.key"
      :title="$t(`szgCard.title${index+1}`)"
      :description="$t(`szgCard.description${index+1}`)"
      :icon="card.icon"
      class="szg-card"
    ></Card>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus.js";
import Card from "@/components/partials/Card.vue";

export default {
  name: "szg-cards",
  components: {
    Card
  },
  data: function() {
    return {
      cards: [
        { key: "consumer", icon: "ConsumerIcon" },
        { key: "trading", icon: "RobustIcon" },
        { key: "security", icon: "SecurityIcon" }
      ]
    };
  },
  methods: {
    isInViewport(el) {
      let elementTop = el.offsetTop;
      let elementBottom = elementTop + el.offsetHeight;

      let viewportTop = window.scrollY;
      let viewportBottom = viewportTop + window.innerHeight;

      return elementBottom > viewportTop && elementTop < viewportBottom;
    },
    handleScroll() {
      if(this.isInViewport(this.$el)){
        EventBus.$emit('in-view')
      }else{
        EventBus.$emit('not-in-view')
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.szg-cards {
  display: flex;
  justify-content: center;
  background: white;
  padding: 60px 0 12vw;

  @media (max-width: 1199.98px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media (max-width: 575.98px) {
    display: block;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.szg-card {
  
}
</style>
